import { useAuthStore } from '@/stores/authStore'

// Definición de rutas por defecto según el rol
const DefaultRoutes = {
	admin: '/admin/dashboard',
	user: '/client/properties',
	employee: '/employee/publications'
}

const RoleSpecificRoutes = {
	admin: ['/admin'],
	user: ['/client'],
	employee: ['/employee']
}

// Función para verificar si una ruta es accesible para un rol
const isRouteAccessibleForRole = (route, role) => 
	RoleSpecificRoutes[role].some(r => route.startsWith(r))

// Función de redirección en base al rol del usuario
const redirectAsRole = (role, toPath) => {
	if (!toPath) {
		return DefaultRoutes[role]
	}
	if (isRouteAccessibleForRole(toPath, role)) {
		return toPath
	}
	return DefaultRoutes[role]
}

export function authGuard(to, from, next) {
	const authStore = useAuthStore()
	const isAuthenticated = authStore.isAuthenticated
	const role = authStore.session?.role
	const redirectTo = to.query.redirectTo
    // console.log(isAuthenticated, role, redirectTo, to.path, to.meta.requiresAuth)
	// Maneja la ruta de logout directamente
	if (to.path === '/auth/logout') {
		if (isAuthenticated) {
			next() // Permite el acceso a /auth/logout
			return
		} else {
			next('/auth/login') // Redirige a login si no está autenticado
			return
		}
	}

	// Si la ruta requiere autenticación y el usuario no está autenticado
	if (to.meta.requiresAuth && !isAuthenticated) {
		next({ path: '/auth/login', query: { redirectTo: to.fullPath } })
		return
	}

	// Si está autenticado, maneja la redirección según el rol
	if (isAuthenticated && role) {
		const targetPath = redirectAsRole(role, redirectTo)
		if (!isRouteAccessibleForRole(to.path, role)) {
			next({ path: targetPath })
			return
		}
	}

	// Si no está autenticado, pero la ruta es accesible sin autenticación (por ejemplo, "/")
	if (!to.meta.requiresAuth && to.path === '/') {
		next()
		return
	}

	next()
}
