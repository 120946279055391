import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'

export const useAuthStore = defineStore('auth', () => {
  const authSessionKey = 'SessionKey'
  const session = ref(undefined)
  const resetPasswordToken = ref(null)

  // Inicializa la sesión desde la cookie al crear el store
  const initializeSession = () => {
    const savedSession = getCookie(authSessionKey);
    console.log(savedSession);
    if (savedSession) {
      session.value = JSON.parse(savedSession);
    }
  };

  // Llama a la función de inicialización al crear el store
  initializeSession();

  // Función para guardar la sesión en el store
  const saveSession = (user) => {
    setCookie(authSessionKey, JSON.stringify(user), {
      httpOnly: false,
      secure: false,
      sameSite: 'strict',
      maxAge: 60 * 60 * 8 // 8 horas
    })
    session.value = user
  }

  // Función para remover la sesión
  const removeSession = () => {
    deleteCookie(authSessionKey)
    session.value = undefined
  }

  const isAuthenticated = computed(() => !!session.value)

  return {
    session,
    isAuthenticated,
    saveSession,
    removeSession,
    resetPasswordToken,
    setResetPasswordToken: (token) => { resetPasswordToken.value = token }
  }
})
