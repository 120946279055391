import { createWebHistory, createRouter } from "vue-router";

// Importación de layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import ClientLayout from '@/layouts/ClientLayout.vue';
import OtherLayout from '@/layouts/OtherLayout.vue';
import { authGuard } from "@/core/guards/authGuard";
import EmployeeLayout from "@/layouts/EmployeeLayout.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";

// Ruta principal
const mainRoute = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/pages/index/index.vue'),
    meta: { requiresAuth: false }
  },
];
// Rutas del Administrador
const adminRoutes = [
	{
		path: '/admin',
		component: AdminLayout,
		children: [
			{
				path: 'dashboard',
				component: () => import('@/pages/admin/Dashboard.vue'),
				meta: { requiresAuth: true, role: 'admin' },//TODO implementar login
			},
		],
	},
]
// Rutas de Autenticación
const authRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      { path: 'login', name: 'auth-login', component: () => import('@/pages/pages/auth-pages/auth-login.vue'), meta: { requiresAuth: false } },
      { path: 'logout', name: 'auth-logout', component: () => import('@/pages/pages/auth-pages/Logout.vue'), meta: { requiresAuth: false } },
      { path: 'signup', name: 'auth-signup', component: () => import('@/pages/pages/auth-pages/auth-signup.vue'), meta: { requiresAuth: false } },
      { path: 're-password', name: 'auth-re-password', component: () => import('@/pages/pages/auth-pages/auth-re-password.vue'), meta: { requiresAuth: false } },
    ],
  },
];

// Rutas del Cliente
const clientRoutes = [
  {
    path: '/client',
    component: ClientLayout,
    children: [
      {
        path: 'publication/new', name: 'register-property', component: () => import('@/pages/client/register-property.vue'),
        meta: { requiresAuth: true, role: 'user' },
      },
      {
        path: 'properties', name: 'client-properties', component: () => import('@/pages/client/properties.vue'),
        meta: { requiresAuth: true, role: 'user' },
      },
      {
        path: 'messages', name: 'client-messages', component: () => import('@/pages/client/messages.vue'),
        meta: { requiresAuth: true, role: 'user' },
      },
      {
        path: 'profile', name: 'client-profile', component: () => import('@/pages/client/profile.vue'),
        meta: { requiresAuth: true, role: 'user' },
      },
    ],
  },
];

// Rutas de Propiedades
const propertyRoutes = [
  {
    path: '/properties',
    component: DefaultLayout,
    children: [
      { path: '', name: 'properties', component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'), meta: { requiresAuth: false } },
      { path: 'projects', name: 'projects', component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'), meta: { requiresAuth: false } },
      { path: 'rent', name: 'rent', component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'), meta: { requiresAuth: false } },
      { path: 'sale', name: 'sale', component: () => import('@/pages/listing/grid-view/grid-sidebar.vue'), meta: { requiresAuth: false } },
      { path: 'detail/:id', name: 'property-detail', component: () => import('@/pages/listing/property-detail/property-detail.vue'), meta: { requiresAuth: false } },
    ],
  },
];

// Rutas Comunes
const commonRoutes = [
  { path: '/pricing', name: 'pricing', component: () => import('@/pages/pages/pricing.vue'), meta: { requiresAuth: false } },
  { path: '/terms', name: 'terms', component: () => import('@/pages/pages/utility/terms.vue'), meta: { requiresAuth: false } },
  { path: '/privacy', name: 'privacy', component: () => import('@/pages/pages/utility/privacy.vue'), meta: { requiresAuth: false } },
  { path: '/contact', name: 'contact', component: () => import('@/pages/contact.vue'), meta: { requiresAuth: false } },
  { path: '/comingsoon', name: 'comingsoon', component: () => import('@/pages/pages/special-pages/comingsoon.vue'), meta: { requiresAuth: false } },
  { path: '/maintenance', name: 'maintenance', component: () => import('@/pages/pages/special-pages/maintenance.vue'), meta: { requiresAuth: false } },
];

// Rutas de Error
const errorRoutes = [
  {
    path: '/404',
    component: OtherLayout,
    children: [
      { path: '', name: '404', component: () => import('@/pages/pages/special-pages/404.vue'), meta: { requiresAuth: false } },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: OtherLayout,
    children: [
      { path: '', component: () => import('@/pages/pages/special-pages/404.vue'), meta: { requiresAuth: false } },
    ],
  },
];
// Rutas del Cliente
const employeeRoutes = [
	{
		path: '/employee',
		component: EmployeeLayout,
		children: [
			{
				path: 'properties',
				component: () => import('@/pages/employee/properties.vue'),
				meta: { requiresAuth: true, role: 'employee' },//TODO implementar login
			},
			{
				path: 'publications',
				component: () => import('@/pages/employee/Publications.vue'),
				meta: { requiresAuth: true, role: 'employee' },//TODO implementar login
			},
		],
	},
]
// Combinación de todas las rutas
const routes = [
  ...mainRoute,  // Ruta principal
  ...authRoutes,
  ...clientRoutes,
  ...employeeRoutes,
  ...adminRoutes,
  ...propertyRoutes,
  ...commonRoutes,  // Rutas comunes actualizadas
  ...errorRoutes,
];

// Configuración del router
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(authGuard);
export default router;
